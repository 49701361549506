/* eslint-disable no-warning-comments */
/* eslint-disable no-unused-vars */

import React, {createContext, useContext, useState} from 'react'

type AppContextValue = {
  isAppReady: boolean
  setIsAppReady: (value: boolean) => void
  qrCodeMode: 'light' | 'dark'
  setQrCodeMode: (value: 'light' | 'dark') => void
}

const initialContextValue: AppContextValue = {
  isAppReady: true,
  setIsAppReady: () => {},
  qrCodeMode: 'light',
  setQrCodeMode: () => {},
}

const AppContext = createContext<AppContextValue>(initialContextValue)

type AppContextProviderProps = {
  children: React.ReactNode
}

export const AppContextProvider = ({children}: AppContextProviderProps) => {
  const [isAppReady, setIsAppReady] = useState<AppContextValue['isAppReady']>(false)
  const [qrCodeMode, setQrCodeMode] = useState<AppContextValue['qrCodeMode']>('light')

  const contextValue: AppContextValue = {
    isAppReady,
    setIsAppReady,
    qrCodeMode,
    setQrCodeMode,
  }

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): AppContextValue => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider')
  }

  return context
}
