import {gsap, Power2} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import * as React from 'react'

import Logo from '@/assets/Logo'
import {SITE_NAME} from '@/common/config'
import {useAppContext} from '@/hooks/useAppContext'

gsap.registerPlugin(ScrollTrigger)

const DELAY = 0
const LOADER_DURATION = 0.1
export const SPLASH_SCREEN_TOTAL_DURATION = DELAY + LOADER_DURATION

type SplashScreenProps = {
  path: string
}

const SplashScreen = ({path}: SplashScreenProps) => {
  const sectionRef = React.useRef()
  const progressRef = React.useRef()

  const {setIsAppReady} = useAppContext()

  const isStoryblokPreview = React.useMemo(() => path === '/en/storyblok-preview/', [path])

  React.useEffect(() => {
    if (isStoryblokPreview) {
      setIsAppReady(true)
    } else {
      const section = sectionRef.current
      const progress = progressRef.current

      if (progress && section) {
        setIsAppReady(false)

        const context = gsap.context(async () => {
          await document.fonts.ready
          ScrollTrigger.refresh()
          gsap
            .timeline({delay: DELAY})
            .to(progress, {
              width: '100%',
              ease: Power2.easeOut,
              duration: LOADER_DURATION,
            })
            .to(section, {
              yPercent: -100,
              pointerEvents: 'none',
              duration: 1.4,
              delay: 0.4,
              ease: Power2.easeOut,
              onStart: () => setIsAppReady(true),
            })
        })

        return () => context.revert()
      }
    }
  }, [setIsAppReady, isStoryblokPreview])

  if (isStoryblokPreview) return null

  return (
    <div
      ref={sectionRef}
      className='fixed inset-0 z-[999999] flex items-center justify-center bg-neutral-10'
    >
      <div>
        <Logo width='300px' height='100%' title={SITE_NAME} />
        <div className='relative mt-8 h-[1px] bg-neutral-60'>
          <div ref={progressRef} className='absolute -top-[1px] left-0 h-[3px] bg-cyan-60' />
        </div>
      </div>
    </div>
  )
}

export default SplashScreen
