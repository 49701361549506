import * as React from 'react'

const Logo = (props: {title?: string} & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={126}
    height={24}
    fill='none'
    viewBox='0 0 126 24'
    {...props}
  >
    <path
      fill='currentColor'
      d='M0 23.6V.4h13.3v4.67H5.12v4.44h7.29v4.61H5.12v4.8h8.18v4.68H0Zm20.74 0c-3.64 0-4.86-1.32-4.86-5V.4h5.16v18.53h7.44v4.67h-7.74Zm9.99 0V.4h13.3v4.67h-8.18v4.44h7.29v4.61h-7.29v4.8h8.17v4.68h-13.3ZM45.68 12c0-6.6 5.52-12 12.2-12 4.89 0 8.65 2.65 10.64 6.56l-4.6 2.32a6.54 6.54 0 0 0-6.01-3.94c-3.83 0-6.68 3.08-6.68 7 0 3.9 2.85 7.09 6.68 7.09a6.63 6.63 0 0 0 6.01-4.01l4.66 2.35A11.6 11.6 0 0 1 57.88 24c-6.71 0-12.2-5.4-12.2-12Zm29.09 11.6V5.07H69.7V.4h15.29v4.67H79.9V23.6h-5.12Zm45.48 0-1-4h-7.32l-1.02 4h-5.22l5.15-18.36c.93-3.28 2.05-5.07 4.96-5.07 2.7 0 4 1.75 4.86 5l5.07 18.43h-5.48Zm-7.04-8.42h4.96l-2.42-9.9-2.54 9.9Zm-13.75-1.25c3.24-1.55 4.43-3.7 4.43-6.2 0-4.62-3.24-7.33-7.72-7.33h-9.23v23.2h5.12l.05-18.56h3.86c1.82 0 2.85 1.2 2.85 2.7-.02 2.68-2.88 3.48-3.82 3.77a2.26 2.26 0 0 0-1.6 2.16c0 .67.4 1.54 1.4 2.11 2.28 1.32 3.98 4.08 4.47 7.82h5.06c-.58-5.2-2.48-8.42-4.87-9.67Z'
    />
  </svg>
)
export default Logo
